<template>
  <div>
    <label class="flex items-center gap-1.5 checkbox-custom">
      <input type="checkbox" name="" class="popup" v-model="checked" :class="error ? '!border-red-500' : ''"/>
      <div
          class="text-sm leading-6 flex-1 font-normal flex gap-1 group flex-wrap"
          :class="error ? 'text-red-500' : 'text-black/60'"
      >
        <p>{{ $t('I_AGREE_TO_THE') }}</p>
        <nuxt-link
            :to="privacy_policy"
            target="_blank"
            class="group-hover:text-primary duration-250 cursor-pointer"
        >
          {{ $t('POLICY') }}
        </nuxt-link>
        <p>{{ $t('AND') }}</p>

        <nuxt-link
            :to="terms_condition"
            target="_blank"
            class="group-hover:text-primary duration-250 cursor-pointer"
        >
          {{ $t('TERMS') }}
        </nuxt-link>
      </div>
    </label>
  </div>
</template>

<script setup lang="ts">
const links_global = inject('globalSettings')?.links[0]

const emits = defineEmits(['update:checked'])
defineProps({
  error: Boolean
})

const checked = ref()

watch(
    () => checked.value,
    () => {
      emits('update:checked', checked.value)
    }
)
const privacy_policy = ref()
const terms_condition = ref()

const routesRender = () => {
  links_global?.translations.map((item) => {
    if (item?.languages_code == useRoute().params.langCode) {
      privacy_policy.value = item?.privacy_policy
      terms_condition.value = item?.terms_condition
    }
  })
}

watch(
    () => useRoute().params.langCode,
    () => {
      routesRender()
    }
)

onMounted(() => {
  routesRender()
})
</script>

<style scoped>
.checkbox-custom input[type='checkbox'].popup {
  border: var(--borderWidth) solid #d2d2d2;
}

.checkbox-custom input[type='checkbox'].popup:checked {
  border: var(--borderWidth) solid #ff8050;
}
</style>
